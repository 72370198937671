/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

// core components
import SimpleFooter from "components/Footers/SimpleFooter";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// index page sections

class Landing extends React.Component {
  state = {};

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Where Innovation Meets Purpose
                      </h1>
                      <p className="lead text-white">
                        leading technology company based in Dubai, UAE,
                        specializing in creating innovative and user-friendly
                        mobile apps. With a team of skilled developers,
                        designers, and testers, we transform ideas into
                        high-performing digital solutions.
                      </p>
                      <div className="btn-wrapper">
                        <a href="#subscribe">
                          <Button
                            className="btn-icon mb-3 mb-sm-0"
                            color="info"
                          >
                            <span className="btn-inner--text">
                              Subscribe Now
                            </span>
                          </Button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Our Vision and Mission
                          </h6>
                          <p className="description mt-3">
                            Our vision is ambitious: to stand tall as a global
                            tech innovator, defying conventions, and reshaping
                            industries with our cutting-edge software solutions.
                            Embracing innovation, we are poised to revolutionize
                            the tech landscape and leave a lasting impact on
                            businesses worldwide.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              design
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              system
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              creative
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Customized Solutions For Your Needs
                          </h6>
                          <p className="description mt-3">
                            We understand that each business has unique
                            requirements. Our approach is to work closely with
                            our clients to gain in-depth insights into their
                            goals and challenges. This enables us to create
                            fully customized software
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              business
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              vision
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              success
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Experienced And Skilled Team
                          </h6>
                          <p className="description mt-3">
                            At EPACK TECHNOLOGIES, we take pride in our
                            exceptional team of experienced software developers,
                            designers, and project managers. With a diverse
                            skill set and a passion for innovation, our team is
                            dedicated to delivering cutting-edge solutions that
                            drive success for our clients.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              marketing
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              product
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              launch
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Providing Software Services</h3>
                    <p>
                      We create, design, deploy, maintain and operate Apps,
                      testing Software, Games and AI Models Development in the
                      UAE that touches lives every day.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Mobile Apps Development</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">SASS Apps</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Web App Development</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Testing Softwares</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <Card className="shadow shadow-lg--hover mt-5">
                      <CardBody>
                        <div className="d-flex px-3">
                          <div>
                            <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                              <i className="ni ni-active-40" />
                            </div>
                          </div>
                          <div className="pl-4">
                            <h5 className="title text-warning">
                              Business intelligence
                            </h5>
                            <p>
                              At Epacken we have professionals with years of
                              experience in providing various BI solutions
                              across multiple industries. Our experts help
                              optimize clients workflows and boost sales.
                            </p>
                            <a
                              className="text-warning"
                              href="https://www.linkedin.com/company/epack-technologies/mycompany/"
                              onClick={(e) => e.preventDefault()}
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Our Platforms</h4>
                      <p className="text-white">
                        With a focus on your unique vision, we harness
                        cutting-edge tools and technologies to elevate your
                        project to new heights. Let us help you unleash the full
                        potential of your company.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Metaverse development
                          </h5>
                          <p>
                            We've an experience in software development and
                            profound knowledge of extended reality, blockchain,
                            and metaverse to deliver state-of-the-art metaverse
                            solutions of various types and scales to businesses
                            and individuals.
                          </p>
                          <a
                            className="text-success"
                            href="https://www.linkedin.com/company/epack-technologies/mycompany/"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Join Us </h2>
                  <p className="lead text-muted">
                    EPACK TECHNOLOGIE is a leading technology company based in
                    Dubai, UAE, specializing in creating innovative and
                    user-friendly mobile apps. With a team of skilled
                    developers, designers, and testers, we transform ideas into
                    high-performing digital solutions.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Join Our Team and Shape the Future!
                      </h3>
                      <p className="lead text-white mt-3">
                        Join us and be a part of something extraordinary.
                        Together, we'll shape the future.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <a
                        href="https://www.linkedin.com/company/epack-technologies/people/"
                        target="_blank"
                      >
                        <Button
                          block
                          className="btn-white"
                          color="default"
                          size="lg"
                        >
                          Meet Our Team
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Build Apps</h2>
                  <p className="lead text-white">
                    We do not settle for one-size-fits-all solutions. We craft
                    tailor-made services that cater to your unique business
                    needs, handpicking the perfect blend of technologies for
                    each project.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Custom Web Development</h5>
                  <p className="text-white mt-3">
                    We build multipurpose web applications to help companies
                    optimize business workflow and resource allocation. Our R&D
                    team assists in minimizing risks and unnecessary expenses
                    during the development of tailored web applications. Our
                    skilled custom software developers are available to serve
                    you.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">API Development</h5>
                  <p className="text-white mt-3">
                    At ePACKEN, we create secure and robust APIs for smooth
                    integration and customization of the existing solutions and
                    help customers facilitate data sharing with their other
                    applications or third-party solutions. We offer custom API
                    integration and automated API testing services across
                    different browsers and operating systems.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">UI / UX Design</h5>
                  <p className="text-white mt-3">
                    ePACKEN web developers and UI/UX designers are proficient in
                    a large variety of design tools, such as Adobe XD, Sketch,
                    Figma, Adobe Illustrator, and others. We follow the latest
                    trends and developments in the industry to create intuitive
                    and eye-catching applications with engaging user interfaces
                    and elaborate functionality.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container id="subscribe">
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Subscribe Now</h4>
                      <p className="mt-0">
                        Let us know about what you've thinking just share with
                        us subscribe@epacken.com
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused,
                        })}
                      ></FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onChange={(e) => {
                              this.setState({ subscribeEmail: e.target.value });
                            }}
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          onChange={(e) => {
                            this.setState({ subscribeMessage: e.target.value });
                          }}
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <a href="mailto:subscribe@epacken.com?subject=Subscriber&body=You can write your message here">
                          <Button
                            block
                            className="btn-round "
                            color="default"
                            size="lg"
                            type="button"
                          >
                            Send Message
                          </Button>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
