import { Link } from "react-router-dom";

// reactstrap components
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {
  Col,
  Container,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown
} from "reactstrap";
import { Logo } from "../../components/SVG/Logo.js";
export default function PrivacyPolicy() {
  return (
    <div>
        {/* Navbar default */}
          <Navbar className="navbar-dark bg-default fixed" expand="lg">
            <Container>
              <NavbarBrand  href="/" onClick={(e) => e.preventDefault()}>
                <Logo /> 
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar-default">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar-default">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/argon-react.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar-default">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ni ni-favourite-28" />
                      <span className="nav-link-inner--text d-lg-none">
                        Discover
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ni ni-notification-70" />
                      <span className="nav-link-inner--text d-lg-none">
                        Profile
                      </span>
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav className="nav-link-icon">
                      <i className="ni ni-settings-gear-65" />
                      <span className="nav-link-inner--text d-lg-none">
                        Settings
                      </span>
                    </DropdownToggle>
                  
                  
                  </UncontrolledDropdown>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>


          {/* Privacy Policy */}
          <div className="container">

          <h4 className="display-4 mt-5 ml-5 text-center ">ElectroPack General Trading L.L.C</h4>
          <h5 className="display-5 mt-5 ml-5 text-center mb-5 text-muted">Privacy Policy & Terms and Conditions</h5>


           <p className="text-muted mb-0 ">At ePacken, accessible from epacken.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ePacken and how we use it.
</p>
<br />
<p className="text-muted mb-0 ">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
</p>
<br />
<p className="text-muted mb-0 ">This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in ePacken. This policy is not applicable to any information collected offline or via channels other than this website.
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
  Consent
</h6>
    <p className="text-muted mb-0">
    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
    </p>
<h6 className="font-bold display-5 text-muted mt-5 font ">
  Information we collect
</h6>
   <p className="text-muted mb-0">
    The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
   </p>
   <p className="text-muted mb-0">
If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
   </p>
   <p className="text-muted mb-0">
When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
   </p>


<h6 className="font-bold display-5 text-muted mt-5 font ">
How we use your information
</h6>
   <p className="text-muted mb-0">
We use the information we collect in various ways, including to:
   </p>


<p className="text-muted mb-0 mt-2" >
  &#x2022; Provide, operate, and maintain our website
</p>
<p className="text-muted mb-0" >
  &#x2022; Improve, personalize, and expand our website
</p>
<p className="text-muted mb-0" >
  &#x2022; Understand and analyze how you use our website
</p>
<p className="text-muted mb-0" >
  &#x2022; Develop new products, services, features, and functionality
</p>
<p className="text-muted mb-0" >
  &#x2022; Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
</p>
<p className="text-muted mb-0" >
  &#x2022; Send you emails
</p>
<p className="text-muted mb-0" >
  &#x2022; Find and prevent fraud
</p>


<h6 className="font-bold display-5 text-muted mt-5 font ">
  Log Files
</h6>
<p className="text-muted mb-0 mt-3" >
ePacken follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
Cookies and Web Beacons
</h6>
<p className="text-muted mb-0 mt-3" >
Like any other website, ePacken uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
</p>


<h6 className="font-bold display-5 text-muted mt-5 font ">
Our Advertising Partners
</h6>
<p className="text-muted mb-0 mt-3" >
Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. 
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
Advertising Partners Privacy Policies
</h6>

<p className="text-muted mb-0 mt-3" >
You may consult this list to find the Privacy Policy for each of the advertising partners of ePacken.
</p>
<p className="text-muted mb-0 mt-3" >
Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ePacken, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
</p>
<p className="text-muted mb-0 mt-3" >
Note that ePacken has no access to or control over these cookies that are used by third-party advertisers.
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
Third Party Privacy Policies
</h6>
<p className="text-muted mb-0 mt-3" >
ePacken's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
</p>
<p className="text-muted mb-0 mt-3" >
You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
CCPA Privacy Rights (Do Not Sell My Personal Information)
</h6>
<p className="text-muted mb-0 mt-3" >
Under the CCPA, among other rights, California consumers have the right to:
</p>
<p className="text-muted mb-0 mt-3" >
Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
</p>
<p className="text-muted mb-0 mt-3" >
Request that a business delete any personal data about the consumer that a business has collected.
</p>
<p className="text-muted mb-0 mt-3" >
Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
</p>
<p className="text-muted mb-0 mt-3" >
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
</p>


<h6 className="font-bold display-5 text-muted mt-5 font ">
GDPR Data Protection Rights
</h6>

<p className="text-muted mb-0 mt-3" >
We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
</p>
<p className="text-muted mb-0 mt-3" >
The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
</p>
<p className="text-muted mb-0 mt-3" >
The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
</p>
<p className="text-muted mb-0 mt-3" >
The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
</p>
<p className="text-muted mb-0 mt-3" >
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
</p>
<p className="text-muted mb-0 mt-3" >
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
</p>
<p className="text-muted mb-0 mt-3" >
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
</p>
<p className="text-muted mb-0 mt-3" >
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
</p>


<h6 className="font-bold display-5 text-muted mt-5 font ">
Children's Information
</h6>
<p className="text-muted mb-0 mt-3" >
Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
</p>
<p className="text-muted mb-0 mt-3" >
ePacken does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
</p>

<h6 className="font-bold display-5 text-muted mt-5 font ">
Changes to This Privacy Policy
</h6>
<p className="text-muted mb-0 mt-3" >
We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
</p>
<h6 className="font-bold display-5 text-muted mt-5 font ">
Contact Us
</h6>
<p className="text-muted mb-0 mt-3" >
If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
</p>

          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

            <SimpleFooter />
          
    </div>

  )
}
