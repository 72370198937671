/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================


=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import { Col, Container, Nav, Row } from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <div className=" bg-gradient-default">
          <br />
          <br />
          <br />
          <Container>
            <Row className="row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className=" text-secondary font-weight-light mb-2">
                  About Epacken
                </h3>
                <br /> <br />
                <small className=" text-secondary w-10">
                  leading technology company based in Dubai, UAE, specializing
                  in creating innovative and user-friendly mobile apps. With a
                  team of skilled developers, designers, and testers, we
                  transform ideas into high-performing digital solutions.
                </small>
                <br /> <br />
                <p className=" text-secondary w-10 font-bold">Head Office:</p>
                <small className=" text-secondary w-10">Jumerah JLT</small>
                <br />
                <small className=" text-secondary w-10">
                  Cluster X3 2306
                </small>{" "}
                <br />
                <small className=" text-secondary w-10">Dubai, Dubai</small>
                <br />
                <small className=" text-secondary w-10">
                Call: +971 (0) 45681269
                </small>{" "}
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6"></Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright text-secondary">
                  © {new Date().getFullYear()} <a target="_blank">Epacken</a>.
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end text-secondary">
                  <small>
                    <a className="text-secondary mr-5" href="/">
                      Epacken
                    </a>
                    <a className="text-secondary  mr-5" href="/privacy-policy">
                      Privacy Policy
                    </a>
                    <a
                      className="text-secondary"
                      href="https://www.linkedin.com/company/epack-technologies/mycompany/"
                    >
                      Linkedin
                    </a>
                  </small>
                </Nav>
              </Col>
            </Row>
          </Container>
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default SimpleFooter;
